import { ref, computed } from "@vue/composition-api";

import { requestLoadSamples } from "@/service/SamplesService";

const allSamples = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getAllSamples = computed(() => allSamples.value);

export const onLoadAllSamples = async ({ prevPage, nextPage, reset }) => {
    let useLast = 0;
    if (!reset) {
        if (nextPage) {
            useLast = lastPageItem;
        }
        if (prevPage) {
            useLast = firstPageItem - 1;
        }
    }
    const reqData = {
        lastItem: useLast,
        perPage: perPage.value,
        filter: {
            search: filterBy.value || null
        }
    };
    loading.value = true;
    try {
        const {
            data: { samples, pagination }
        } = await requestLoadSamples(reqData);

        if (samples) {
            allSamples.value.splice(0, allSamples.value.length);
            allSamples.value.push(...samples);
        } else {
            allSamples.value.splice(0, allSamples.value.length);
        }
        lastPageItem = pagination.lastItem;
        firstPageItem = pagination.firstItem;
        totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
    } finally {
        loading.value = false;
    }
};
