<template>
  <b-card no-body title="Samples">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onLoadAllSamples">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getAllSamples" :current-page="currentPage">
        <template #cell(inventoryTag)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
          <br />
          <small>
            <b>Name: </b> {{ data.item.listingName }} <br />
            <b>Price: </b> {{ showAsMoney(data.item.listingPrice) }} ({{ data.item.listingPriceType }}) <br />
            <b>Pkg Dt: </b> {{ showDateInFormat(data.item.packageDate) }}
          </small>
        </template>
        <template #cell(requestedDate)="data">
          {{ showDateInFormat(data.value) }}
        </template>
        <template #cell(shippedDate)="data">
          {{ data.value ? showDateInFormat(data.value) : "" }}
        </template>
        <template #cell(requester)="data">
          {{ data.item.requestingFacilityName }} - {{ data.item.requestingFacilityLicense }} <br />
          <b>Requesting User: </b>{{ data.item.requestingUser }}
        </template>
        <template #cell(reviewed)="data">
          <template v-if="!!data.item.sampleNotes && !!data.item.sampleNotes.length > 0">
            <feather-icon icon="CheckCircleIcon" size="18" class="text-success" />
          </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" size="18" class="text-danger" />
          </template>
        </template>
        <template #cell(samplePrice)="data"> {{ showAsMoney(data.value) }} </template>
        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="6" class="mb-1">
                <span v-for="item in row.item.tags" :key="item">
                  <b-badge variant="light-primary">{{ item }}</b-badge> &nbsp;
                </span>
              </b-col>
            </b-row>
            <b>Sample Uid: </b>{{ row.item.sampleUid }} <br />
            <b>Listing Uid: </b>{{ row.item.listingUid }} <br /><br />
            <b>Requesting Facility Uid: </b>{{ row.item.requestingFacilityUid }} <br /><br />

            <template v-if="row.item.sampleNotes && row.item.sampleNotes.length > 0">
              <b>Sample Notes: </b><br />
              <b-table responsive :fields="noteFields" :items="row.item.sampleNotes">
                <template #cell(description)="data">
                  <small><b>Aroma: </b>{{ data.item.aroma }}</small> <br />
                  <small> <b>Color: </b>{{ data.item.color }}</small> <br />
                  <small><b>Essence: </b>{{ data.item.essence }}</small> <br />
                  <small><b>Grade: </b>{{ data.item.grade }}</small> <br />
                </template>
                <template #cell(sharedNote)="data">
                  {{ data.value }} <br />
                  <small><b>Share Note With Seller: </b>{{ data.item.shareNoteWithSeller }}</small>
                </template>
                <template #cell(noteDate)="data">
                  {{ showDateInFormat(data.value) }}
                </template>
              </b-table>
            </template>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { getAllSamples, filterBy, setPerPage, getLoading, onLoadAllSamples, getTotalRows, getPerPage } from "@/views/samples/sampleRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { toMoneyUS } from "@/service/NumberService";

import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AllSamples",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  props: {
    sampleRow: null
  },
  setup(props, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      // 'mark_resolved',
      {
        key: "inventoryTag",
        label: "Listing"
      },
      "samplePrice",
      "reviewed",
      "requester",
      "requestedDate",
      "shippedDate"
    ];

    const noteFields = [
      // 'mark_resolved',
      "description",
      "trimQuality",
      "overallQuality",
      "note",
      "sharedNote",
      "byUser",
      "noteDate",
      "noteUid"
    ];

    watch(filterBy, (newValue, oldValue) => {
      initalLoadSamples();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD");

    const showAsMoney = (amt) => toMoneyUS(amt);

    const initalLoadSamples = async () => onLoadAllSamples({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadSamples();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllSamples({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllSamples({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllSamples({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      if (props.sampleRow) {
        filterBy.value = props.sampleRow.facilityLicense;
      } else {
        filterBy.value = "";
      }
    });

    return {
      getAllSamples,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllSamples,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      currentPage,
      pageOptions,
      fields,
      noteFields,
      showAsMoney
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
</style>
